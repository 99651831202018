/**
 * 🚨Hack alert!
 * This component relies on the 'react-text-transition' module, which includes
 * references to `window` and `document` which throw errors when Gatsby builds
 * in a context where those don't exist. To get around this, we only require
 * the module if `window` is undefined and only render the TextTransition node
 * if the module has been loaded.
 */

import React, { useState, useEffect } from 'react'
import { Box } from '@xstyled/styled-components'
import { Row, Col, Container } from './grid'
import styled, { css } from '@xstyled/styled-components'
import { config as presets } from 'react-spring'
import heroText from '../images/hero-text.svg'
import heroBg from '../images/hero-bg-shapes.svg'
import { breakpoints } from '@xstyled/system'
import Button from './button'
import { navigate } from 'gatsby'

let TextTransition
;(() => {
  if (typeof window !== 'undefined') {
    const imported = require('react-text-transition')
    TextTransition = imported.default
  }
})()

const StyledHero = styled(Box)`
  background-image: url(${heroBg});
  background-color: secondary;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 50%;

  ${breakpoints({
    xs: css`
      height: 700px;
    `,
    md: css`
      height: 950px;
    `,
    lg: css`
      height: 690px;
    `,
  })}
`

const TextCarouselWrapper = styled(Box)`
  display: inline-block;
  margin: 0 0.2em;
  font-family: heading;
  color: primary;
  width: 5em;

  & .text-transition_inner > div {
    text-align: center;
    width: 5em;
  }
`

const CtaBox = styled(Box)`
  margin: 5 auto;
  position: absolute;
  width: 90%;
  z-index: 0;

  & button {
    margin: 0 auto;

    &:before {
      background-color: white;
      border-color: white;
    }
  }
`

const TEXTS = [
  'build',
  'invest in',
  'believe in',
  'hand-pick',
  'nurture',
  'attract',
  'hunt for',
  'launch',
  'unearth',
  'elevate',
]

const Hero = () => {
  const [textIndex, setTextIndex] = useState(0)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    let interval

    if (!loaded) {
      setLoaded(typeof TextTransition !== 'undefined')
    }

    interval = setInterval(
      () => setTextIndex((index) => index + 1),
      2000 // every 2 seconds
    )

    return () => clearInterval(interval)
  }, [loaded])

  return (
    <StyledHero>
      <Container>
        <Row>
          <Col
            col={{ xs: 1, md: 6 / 8, lg: 8 / 12 }}
            mx="auto"
            textAlign="center"
            mt={{ xs: 7, md: 6, lg: 6 }}
          >
            <img
              src={heroText}
              alt="A startup playground"
              width="100%"
              style={{ maxWidth: '656px' }}
            />
          </Col>
        </Row>
        <Row
          fontFamily="body"
          fontSize={{ xs: '1.4em', md: '2em', lg: '2.5em' }}
          mt={{ xs: 5, md: 6, lg: 7 }}
        >
          <Col
            col={{ xs: 1, md: 8 / 8, lg: 8 / 12 }}
            color="white"
            fontWeight="bold"
            textAlign={{ xs: 'center', md: 'left', lg: 'left' }}
          >
            We
            <TextCarouselWrapper>
              {loaded && (
                <TextTransition
                  text={TEXTS[textIndex % TEXTS.length]}
                  springConfig={presets.wobbly}
                  direction="down"
                />
              )}
            </TextCarouselWrapper>
            the best startups.
          </Col>
        </Row>
        <Row
          fontFamily="heading"
          color="body"
          fontSize={{ xs: '1.4em', md: '2em', lg: '2.5em' }}
          textAlign={{ xs: 'center', md: 'left', lg: 'left' }}
          mt="4"
        >
          <Col col={{ xs: 1, md: 1, lg: 8 / 12 }}>Hard work pays.</Col>
        </Row>
      </Container>
    </StyledHero>
  )
}

export default Hero
