import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import SubHeader from '../components/sub-header'
import { Row, Col, Container } from '../components/grid'
import styled, { Box, breakpoints, css } from '@xstyled/styled-components'

import startup8 from '../images/home-bulb.svg'
import startup7 from '../images/home-mail.png'
import startup6 from '../images/home-cash.svg'
import Text from '../components/text'
import { Link } from 'gatsby'

const StyledContainer = styled(Container)(
  () => css`
    ${breakpoints({
      xs: css`
        padding-top: 5;
        padding-bottom: 5;
      `,
      md: css`
        padding-top: 5;
        padding-bottom: 5;
      `,
      lg: css`
        padding-top: 5;
        padding-bottom: 5;
      `,
    })}
  `
)

const IconText = styled(Text)`
  font-family: 'MTTMilano';
  font-size: 1.5em;
  line-height: 1.5em;
  text-align: center;
  ${breakpoints({
    xs: css`
      font-size: 1.25em;
    `,
    lg: css`
      font-size: 1.5em;
    `,
  })}
`

const Image = styled.img(
  () => css`
    object-fit: contain;
    height: 100%;
    width: 100%;
  `
)

const StyledLink = styled(Link)`
  color: body;
  text-decoration: none;
  border-bottom: 3px solid;
  border-bottom-color: secondary;
`

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Layout>
      <Hero />
      <StyledContainer>
        <Box mb="5">
          <SubHeader mt={0} mx="auto">
            Come and play...
          </SubHeader>
        </Box>
        <Row justifyContent="center" alignItems="flexStretch">
          <Col col={{ xs: 1, lg: 1 / 3 }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mb={{ xs: 5, md: 0 }}
            >
              <Box height={200} display="flex" alignItems="center">
                <Image src={startup6} />
              </Box>
              <IconText>
                Are you an exciting founder with a great opportunity and looking
                for investment? <StyledLink to='/startup'>Come join us</StyledLink> and we’ll invest and guide you on
                the journey to your dream exit.
              </IconText>
            </Box>
          </Col>
          <Col col={{ xs: 1, lg: 1 / 3 }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mb={{ xs: 5, md: 0 }}
            >
              <Box height={200} display="flex" alignItems="center">
                <Image src={startup7} />
              </Box>
              <IconText>
                Want access to our incredible deal flow? <StyledLink to='/syndicate'>Come join our free
                syndicate</StyledLink> to get access to the UK’s most exciting startups and
                invest alongside us.
              </IconText>
            </Box>
          </Col>
          <Col col={{ xs: 1, lg: 1 / 3 }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mb={{ xs: 5, md: 0 }}
            >
              <Box height={200} display="flex" alignItems="center">
                <Image src={startup8} />
              </Box>
              <IconText>
                New to the startup world? Check out our <StyledLink to='/startup-tools'>free tools</StyledLink> to help you on your way; including investor pitch deck template and other financial trackers.
              </IconText>
            </Box>
          </Col>
        </Row>
      </StyledContainer>
    </Layout>
  </>
)

export default IndexPage
